gl-transaction-create {
  @import '../variables';

  display: flex;
  flex-direction: column;
  min-height: calc(~"100vh - @{stickyFooterHeight}");

  .transaction-create-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .transaction-create-form {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
    margin: 0;
    padding-bottom: 30px;
  }

  .cursor-default {
    cursor: default;
  }

  .transaction-create-type {

    &-container {
      align-items: center;
      padding: 20px 0 20px 40px;
    }

    &-label {
      font-weight: bold;
      margin: 0 8px 0 0;
    }

    &-select {
      margin: 0;
    }

  }

  .transaction-create-date {

    &-container {
      align-items: center;
      padding: 20px 0 20px 40px;
    }

    &-label {
      font-weight: bold;
      margin: 0 8px 0 0;
    }

    &-select {
      margin: 0;
    }

  }

  .transaction-create-table {
    input {
      margin: 0;
    }

    .no-column-header {
      width: 40px;
    }
  }

  .transaction-create-footer {
    td {
      background-color: @bodyColor;
      outline: none;
    }

    tr:hover > td {
      background-color: @bodyColor;
    }

    .transaction-remarks-column {
      padding-left: 5px;
      padding-right: 0;
    }

    .total-label {
        font-weight: bold;
        padding-right: 8px;
        margin: 0;
      }
  }

  .transaction-create-submit-buttons {
    align-self: flex-end;
  }
}